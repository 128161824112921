// import libs
import React, { useEffect, useState } from "react"
import { useDbContext } from "../db"
import { useMapContext } from "../map"
import GeoJSON from "ol/format/GeoJSON"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { featuresStyle, foxtownStyle } from "../styles"
import { Modify, Select, defaults as defaultInteractions } from "ol/interaction"
import { SelectEvent } from "ol/interaction/Select"
import { doubleClick, singleClick } from "ol/events/condition"

// import components
import { FeatureModal } from "../drawers"

export const FeaturesLayer = ({ zIndex = 10 }) => {

  // context
  const { map, editFeatures, setFoxtownSource } = useMapContext()
  const { localFeatures, foxtownFeatures, updateFeature, currentDbName } = useDbContext()

  // state
  const [ localFeaturesData, setLocalFeatures ] = useState<any>()
  const [ foxtownFeaturesData, setFoxtownFeatures ] = useState<any>()
  const [ feature, setFeature ] = useState<any>()
  const [ featureModal, setFeatureModal] = useState(false)

  // add vector layer to map
  useEffect(() => {
    if (!map) return;

    const localGeojson = new GeoJSON().readFeatures(localFeaturesData)
    const foxtownGeojson = new GeoJSON().readFeatures(foxtownFeaturesData)

    const localSource = new VectorSource({
      features: localGeojson
    })

    const foxtownSource = new VectorSource({
      features: foxtownGeojson
    })
    setFoxtownSource(foxtownSource)

    let localLayer = new VectorLayer({
      source: localSource,
      style: (feature) => featuresStyle(feature, map),
      properties: {
        name: 'features'
      },
    })

    let foxtownLayer = new VectorLayer({
      source: foxtownSource,
      style: (feature) => foxtownStyle(feature, map),
      properties: {
        name: 'foxtown'
      },
    })

    map.addLayer(localLayer)
    map.addLayer(foxtownLayer)

    localLayer.setZIndex(zIndex)
    foxtownLayer.setZIndex(zIndex)

    return () => {
      if (map) {
        map.removeLayer(localLayer)
        map.removeLayer(foxtownLayer)
      }
    }

  }, [map, localFeatures, foxtownFeatures])

  // build geojson local features source
  useEffect(() => {

    const localFeaturesData = localFeatures.map((f: any) => {
      const feature = JSON.parse(JSON.stringify(f.doc.geojson))
      feature.properties._id = f.doc._id
      feature.properties._rev = f.doc._rev
      feature.properties.geojson = f.doc.geojson
      return feature
    })

    const geojsonObject = {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:3857'
        }
      },
      'features': localFeaturesData
    }

    setLocalFeatures(geojsonObject)

  }, [localFeatures])

  // build geojson local features source
  useEffect(() => {

    const foxtownFeaturesData = foxtownFeatures.map((f: any) => {
      const feature = JSON.parse(JSON.stringify(f.doc.geojson))
      feature.properties._id = f.doc._id
      feature.properties._rev = f.doc._rev
      feature.properties.geojson = f.doc.geojson
      return feature
    })

    const geojsonObject = {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:3857'
        }
      },
      'features': foxtownFeaturesData
    }

    setFoxtownFeatures(geojsonObject)

  }, [foxtownFeatures])

  /**
   * Edit features
   */
  useEffect(() => {
    if (!map) return

    // select interaction
    const select = new Select({
      condition: singleClick,
      layers: (layer) => {
        const layerName = layer.get('name')
        if (layerName === currentDbName) {
          return true
        } else {
          return false
        }
      },
    })

    // save feature on deselect
    select.on('select', (evt: SelectEvent) => {
      if (evt.deselected.length !== 0) {
        const feature = evt.deselected[0]
        // parse geojson for the feature
        const parser = new GeoJSON()
        const geojson = parser.writeFeatureObject(feature)
        // build doc
        const doc = {
          _id: feature.get('_id'),
          _rev: feature.get('_rev'),
          geojson: geojson
        };
        // remove unneeded properties
        delete(doc.geojson.properties?._id)
        delete(doc.geojson.properties?._rev)
        delete(doc.geojson.properties?.geojson)
        // update feature in db
        updateFeature(doc)
      }
    })

    // alt select
    const altSelect = new Select({
      condition: doubleClick,
      layers: (layer) => {
        const layerName = layer.get('name')
        if (layerName === currentDbName) {
          return true
        } else {
          return false
        }
      },
    })

    altSelect.on('select', (evt: SelectEvent) => {
      if (evt.selected.length > 0) {
        setFeature(evt.selected[0])
        setFeatureModal(true)
      } else {
        setFeature(null)
        setFeatureModal(false)
      }
    })

    // modify interaction
    const modify = new Modify({
      features: select.getFeatures()
    })

    const defaults = defaultInteractions({
      doubleClickZoom: false
    }).extend([select, altSelect, modify])

    if (editFeatures) {
      defaults.forEach((interaction) => {
        map.addInteraction(interaction)
      })
    } else {
      defaults.forEach((interaction) => {
        map.removeInteraction(interaction)
      })
    }

    return () => {
      defaults.forEach((interaction) => {
        map.removeInteraction(interaction)
      })
    }
  }, [map, localFeatures, foxtownFeatures, currentDbName, editFeatures])



  return (
    <FeatureModal
      feature={feature}
      setFeature={setFeature}
      featureModal={featureModal}
      setFeatureModal={setFeatureModal}
    />
  )
}