// import libs
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useMapContext } from "../map";
import { useDbContext } from "../db";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { BottomDrawer } from "../shared-ui/bottom-drawer";
import { getCenter } from "ol/extent";
import { transform } from "ol/proj"

export interface IFeatureModalProps {
  featureModal: boolean,
  setFeatureModal: Dispatch<SetStateAction<boolean>>,
  feature: any,
  setFeature: Dispatch<SetStateAction<any>>
}

export const FeatureModal = ({ featureModal, setFeatureModal, feature, setFeature }: IFeatureModalProps) => {

  const {
    map
  } = useMapContext();

  const {
    updateFeature,
    removeFeature,
  } = useDbContext()

  const [featureTitle, setFeatureTitle] = useState("");
  const [featureColor, setFeatureColor] = useState("purple");

  useEffect(() => {
    if (!map || !featureModal) return;
    if (feature) {
      setFeatureTitle(feature.get('title'));
      setFeatureColor(feature.get('color'));
    } else {
      setFeatureTitle("");
      setFeatureColor("purple");
    }

    return () => {
      if (feature) {
        setFeature(null)
        setFeatureModal(false)
      }
    }
  }, [feature]);

  if (!featureModal) return <></>;

  return (
    <BottomDrawer isOpen={featureModal} setIsOpen={setFeatureModal}>
      <div className="relative z-[100000] px-5 pb-16 pt-6 bg-white/50 backdrop-blur-sm rounded-tr-md max-w-log">
        <div className="mt-3 text-slate-900 grid grid-cols-7 items-center">
          <label className="col-span-2 text-sm">Title</label>
          <input
            type="text"
            placeholder="feature title"
            value={featureTitle}
            className="mt-1 px-5 py-3 bg-slate-900 text-white rounded-md w-full col-span-5 text-sm"
            onChange={(e) => setFeatureTitle(e.target.value)}
          />
        </div>
        <div className="mt-3 text-slate-900 grid grid-cols-7 items-center">
          <label className="col-span-2 text-sm">Color</label>
          <div className="mt-1 col-span-5 flex gap-3">
            <div
              className={`cursor-pointer h-5 w-5 rounded-full bg-red-500 border-2 ${
                featureColor === "red" ? "border-white" : "border-red-500"
              }`}
              onClick={() => setFeatureColor("red")}
            />
            <div
              className={`cursor-pointer h-5 w-5 rounded-full bg-green-500 border-2 ${
                featureColor === "green" ? "border-white" : "border-green-500"
              }`}
              onClick={() => setFeatureColor("green")}
            />
            <div
              className={`cursor-pointer h-5 w-5 rounded-full bg-blue-500 border-2 ${
                featureColor === "blue" ? "border-white" : "border-blue-500"
              }`}
              onClick={() => setFeatureColor("blue")}
            />
            <div
              className={`cursor-pointer h-5 w-5 rounded-full bg-purple-500 border-2 ${
                featureColor === "purple" ? "border-white" : "border-purple-500"
              }`}
              onClick={() => setFeatureColor("purple")}
            />
            <div
              className={`cursor-pointer h-5 w-5 rounded-full bg-slate-900 border-2 ${
                featureColor === "slate" ? "border-white" : "border-slate-900"
              }`}
              onClick={() => setFeatureColor("slate")}
            />
          </div>
        </div>
        <div className="mt-8 flex justify-between">
          <div className="flex flex-row">
            <button
              className="btn btn-sm btn-success mr-2"
              onClick={() => {

                const geojson = feature.get('geojson')
                geojson.properties.color = featureColor
                geojson.properties.title = featureTitle

                const doc = {
                  _id: feature.get('_id'),
                  _rev: feature.get('_rev'),
                  geojson: geojson
                };
                // update the feature
                updateFeature(doc);
                // update feature state
                setFeatureModal(false);
                setFeature(null);
              }}
            >
              Save
            </button>
            <button
              className="btn btn-sm btn-outline mr-2"
              onClick={() => {
                setFeatureModal(false);
                setFeature(null);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-outline "
              onClick={() => {
                const center = getCenter(feature.getGeometry().getExtent())
                const coords = transform(center, 'EPSG:3857', 'EPSG:4326')
                const url = `https://maps.google.com/?q=${coords[1]},${coords[0]}`;
                window.open(url);
              }}
            >
              <GlobeAltIcon className="h-4 w-4" />
            </button>
          </div>
          <div>
            <button
              className="btn btn-sm btn-error"
              onClick={() => {
                const doc = {
                  _id: feature.get('_id'),
                  _rev: feature.get('_rev'),
                };
                removeFeature(doc);
                setFeatureModal(false);
                setFeature(null);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </BottomDrawer>
  );
};
