// import libs
import React, { useEffect, useState } from "react";
import { isBrowser } from "../utils/constants";
import { useDbContext } from "../components/db";

// import components
import { useMapContext } from "../components/map";
import { Layers, TileLayer, FeaturesLayer, DrawLayer, LocationLayer } from "../components/layers"

// import sources
import {
  baseMap,
  lidarMap,
  satelliteMap,
  usfsMap,
} from "../components/sources"

export const Map = () => {

  if (!isBrowser) {
    return null;
  }

  const { offlineTilesDb } = useDbContext()

  const {
    lidarEnabled, lidarOpacity,
    satelliteEnabled, satelliteOpacity,
    usfsEnabled, usfsOpacity
  } = useMapContext()

  const [offlineTiles, setOfflineTiles] = useState<any>(null)

  useEffect(() => {
    if (!offlineTilesDb) return;

    offlineTilesDb.allDocs({
      include_docs: true,
      attachments: true,
      binary: true
    }).then((docs) => {
      setOfflineTiles(docs.rows)
    })

  }, [offlineTilesDb])

  return (
    <>
      <Layers>
        {offlineTiles && (
          <>
            <TileLayer source={baseMap(offlineTiles)} name="baseMap" opacity={100} visible={true} />
            <TileLayer source={lidarMap(offlineTiles)} name="lidarMap" opacity={lidarOpacity} visible={lidarEnabled} />
            <TileLayer source={satelliteMap(offlineTiles)} name="satelliteMap" opacity={satelliteOpacity} visible={satelliteEnabled} />
            <TileLayer source={usfsMap(offlineTiles)} name="usfsMap" opacity={usfsOpacity} visible={usfsEnabled} />
          </>
        )}

        <FeaturesLayer />
        <DrawLayer />

        <LocationLayer />
      </Layers>
    </>
  );
};

export default Map;
