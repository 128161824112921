// import libs
import React from "react"

export interface ILayers {
  children: React.ReactNode
}

export const Layers = ({ children }: ILayers) => {
  return <div>{children}</div>
}
