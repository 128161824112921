// import libs
import React from "react";

export interface ISectionProps {
  children: React.ReactNode
}

export const Section = ({ children }: ISectionProps) => {
  return <div className="px-5 py-5">{children}</div>;
};

export default Section;
