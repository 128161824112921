// import libs
import React from "react";

export interface IBoxProps {
  children: React.ReactNode
  title: string
  className?: string
}

export const Box = ({ children, title, className }: IBoxProps) => {
  return (
    <div className={`px-3 pt-3 md:px-5 md:pt-5`}>
      <div className={`bg-white rounded-lg ${className}`}>
        <div className="px-5 py-3 bg-emerald-600 rounded-tl-lg rounded-tr-lg text-white">
          <h2 className="text-lg">{title}</h2>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Box;
