// import libs
import { createContext, Dispatch, SetStateAction, useContext } from "react"
import Map from "ol/Map"

export interface IMapContext {
  // map
  map: null | Map,
  mapLoadMessage: string
  setMapLoadMessage: Dispatch<SetStateAction<string>>
  // sources
  foxtownSource: any,
  setFoxtownSource: Dispatch<SetStateAction<any>>
  // tiles
  lidarEnabled: boolean
  setLidarEnabled: (enabled: boolean) => void
  lidarOpacity: number
  setLidarOpacity: (opacity: number) => void
  satelliteEnabled: boolean
  setSatelliteEnabled: (enabled: boolean) => void
  satelliteOpacity: number
  setSatelliteOpacity: (opacity: number) => void
  usfsEnabled: boolean
  setUsfsEnabled: (enabled: boolean) => void
  usfsOpacity: number
  setUsfsOpacity: (opacity: number) => void
  // contorls
  editMode: string
  setEditMode: Dispatch<SetStateAction<string>>
  // location
  locationEnabled: boolean,
  followLocation: boolean,
  toggleFollowLocation: () => void,
  heading: number,
  setHeading: Dispatch<SetStateAction<number>>
  // features
  editFeatures: boolean,
  setEditFeatures: Dispatch<SetStateAction<boolean>>
  // details
  detailsModal: boolean,
  setDetailsModal: Dispatch<SetStateAction<boolean>>
  // admin
  foxtownRep: boolean
  setFoxtownRep: (rep: boolean) => void
  foxtownPass: string
  setFoxtownPass: (pass: string) => void
  foxtownHash: string
  setFoxtownHash: (hash: string) => void
}

export const defaultState = {
  // map
  map: null,
  mapLoadMessage: "Loading maps...",
  setMapLoadMessage: () => null,
  // sources
  foxtownSource: null,
  setFoxtownSource: () => null,
  // tiles
  lidarEnabled: true,
  setLidarEnabled: () => null,
  lidarOpacity: 50,
  setLidarOpacity: () =>  null,
  satelliteEnabled: false,
  setSatelliteEnabled: () => null,
  satelliteOpacity: 50,
  setSatelliteOpacity: () => null,
  usfsEnabled: false,
  setUsfsEnabled: () => null,
  usfsOpacity: 20,
  setUsfsOpacity: () => null,
  editMode: 'None',
  setEditMode: () => null,
  // location
  locationEnabled: false,
  followLocation: false,
  toggleFollowLocation: () => null,
  heading: 0,
  setHeading: () => null,
  // controls
  editFeatures: false,
  setEditFeatures: () => null,
  // details
  detailsModal: false,
  setDetailsModal: () => null,
  // admin
  foxtownRep: false,
  setFoxtownRep: () => null,
  foxtownPass: "",
  setFoxtownPass: () => null,
  foxtownHash: "",
  setFoxtownHash: () => null,
}

// app context
export const MapContext = createContext<IMapContext>(defaultState);

export const useMapContext = () => useContext(MapContext);
