// import libs
import React from "react";
import { useAppContext } from "../app/app-context";

// import components
import { Drawer, Header, Box, Section } from "../shared-ui";
import { TiSocialYoutube, TiSocialFacebook } from "react-icons/ti";
import { Rules } from "../rules";

export const Menu = () => {
  const { openMenu, setOpenMenu } = useAppContext();

  return (
    <Drawer isOpen={openMenu} setIsOpen={setOpenMenu}>
      <Header title="Foxtown" setIsOpen={setOpenMenu} />

      <Box title="Foxtown Boulders">
        <Section>
          <p className="txt-sm mb-3">
            The following waiver must be filled out in order to climb at the
            Crypt, Sacred Ruins, Glass House, and Lost City sectors at Foxtown
            Boulders.
          </p>
          <div>
            <button
              className="btn btn-md bg-emerald-600 hover:bg-emerald-700 border-0"
              onClick={() =>
                window.open(
                  "https://form.jotform.com/222814097630051",
                  "_blank"
                )
              }
            >
              Foxtown Boulders Waiver
            </button>
          </div>
        </Section>
      </Box>

      <Box title="Elsewhere">
        <Section>
          <ul className="space-y-1">
            <li>
              <a
                className="flex flex-row gap-1 items-center"
                href="https://www.youtube.com/channel/UCili7AhdTnTpxX-LDuZz6kg"
                target="_blank"
              >
                <TiSocialYoutube size={28} color="#ff0000" />
                <span>YouTube</span>
              </a>
            </li>
            <li>
              <a
                className="flex flex-row gap-1 items-center"
                href="https://www.facebook.com/profile.php?id=100038357476826"
                target="_blank"
              >
                <TiSocialFacebook size={28} color="#4267B2" />
                <span>Facebook</span>
              </a>
            </li>
          </ul>
        </Section>
      </Box>

      <Rules />
    </Drawer>
  );
};

export default Menu;
