// import libs
import React from "react"
import ReactJoyride, { Step, CallBackProps } from "react-joyride"
import { useAppContext } from "../app"

interface IContainerProps {
  children: React.ReactNode
}

export const Joyride = () => {

  const { joyride, setJoyride } = useAppContext()

  const Container = ({ children }: IContainerProps) => (
    <div className="text-sm">{children}</div>
  )

  const foxtownMenu = (
    <Container>
      <p>When clmibing on private property in the Foxtown Sector, fill out the Foxtown Waiver here.</p>
    </Container>
  )

  const foxtownSearch = (
    <Container>
      <p>Search for established bouldering areas or your personal map features.</p>
    </Container>
  )

  const foxtownLocation = (
    <Container>
      <p>Cycle through turning on your location (green), following your location (blue),
        or turning your location off.</p>
    </Container>
  )

  const foxtownMapTools = (
    <Container>
      <p>Drop pins, plot sectors, and draw paths that indicate distance.
        Click the pencil icon to edit features after they've been added to the map.</p>
    </Container>
  )

  // const foxtownAddPoint = (
  //   <Container>
  //     <p>Add a single point to the map.</p>
  //   </Container>
  // )

  // const foxtownAddPolygon = (
  //   <Container>
  //     <p>Draw a polygon on the map.</p>
  //   </Container>
  // )

  // const foxtownAddLine = (
  //   <Container>
  //     <p>Draw a line that displays distance on the map.</p>
  //   </Container>
  // )

  // const foxtownEditFeatures = (
  //   <Container>
  //     <p>Edit the shape and location of features with a single click. Edit feature details with a double click.</p>
  //   </Container>
  // )

  const foxtownSettings = (
    <Container>
      <p>Adjust maps displays and thier different settings.</p>
    </Container>
  )

  const foxtownSync = (
    <Container>
      <p>Features you add to your personal map automatically save to the cloud.
        Save your sync code to sync across devices.</p>
    </Container>
  )

  const steps: Step[] = [
    {
      target: '.foxtown-menu',
      title: "Main Menu",
      content: foxtownMenu,
      placementBeacon: 'left',
    },
    {
      target: '.foxtown-search',
      title: "Search Geodata",
      content: foxtownSearch,
      placementBeacon: 'left'
    },
    {
      target: '.foxtown-location',
      title: "Show Location",
      content: foxtownLocation,
      placementBeacon: 'left'
    },
    {
      target: '.foxtown-map-tools',
      title: "Map Toolkit",
      content: foxtownMapTools,
      placementBeacon: 'left'
    },
    // {
    //   target: '.foxtown-add-point',
    //   title: "Add Point",
    //   content: foxtownAddPoint,
    //   placementBeacon: 'left'
    // },
    // {
    //   target: '.foxtown-add-polygon',
    //   title: "Draw Polygon",
    //   content: foxtownAddPolygon,
    //   placementBeacon: 'left'
    // },
    // {
    //   target: '.foxtown-add-line',
    //   title: "Draw Line",
    //   content: foxtownAddLine,
    //   placementBeacon: 'left'
    // },
    // {
    //   target: '.foxtown-edit-features',
    //   title: "Edit Map Features",
    //   content: foxtownEditFeatures,
    //   placementBeacon: 'left'
    // },
    {
      target: '.foxtown-settings',
      title: "Map Settings",
      content: foxtownSettings,
      placementBeacon: 'left'
    },
    {
      target: '.foxtown-sync',
      title: "Cloud Sync",
      content: foxtownSync,
      placementBeacon: 'left',
    },
    // {
    //   target: '.foxtown-sync-box',
    //   title: "Sync Settings",
    //   content: foxtownSyncBox,
    //   placementBeacon: "bottom",
    //   placement: "bottom"
    // }
  ]

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, lifecycle, step: { target } } = data;

    console.log(action, index, status, type, lifecycle)
    console.log(steps.length)
    // if (lifecycle === "complete" && target === ".foxtown-menu") {
    //   setJoyride(false)
    // }

    // if (index+1 == steps.length) {

    // }
  }

  return (
    <ReactJoyride
      steps={steps}
      continuous={true}
      // locale={{
      //   back: 'Back',
      //   close: 'Close'
      // }}
      showProgress={false}
      callback={handleJoyrideCallback}
      run={joyride}
    />
  )
}