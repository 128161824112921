// import libs
import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import { groupBy } from "lodash";
import { useAppContext } from "../app";
import { useMapContext } from "../map";
import { useDbContext } from "../db";
import GeoJSON from "ol/format/GeoJSON";

// import components
import { Drawer, Header, NavPanel } from "../shared-ui";

import { HiOutlineLocationMarker } from "react-icons/hi"
import { TbPolygon } from "react-icons/tb"
import { GiPathDistance } from "react-icons/gi"

// import data
import { foxtownBoulders } from "../../data";

export const Data = () => {

  const { openData, setOpenData } = useAppContext();
  const { localFeatures } = useDbContext()

  const [navPanel, setNavPanel] = useState('foxtownPanel')
  const [subPanelOpen, setSubPanelOpen] = useState(false)
  const [width, setWidth] = useState(0)

  const openPanel = (panel: string) => {
    setNavPanel(panel)
    setSubPanelOpen(true)
  }

  const panelRef = useRef<any>()

  useEffect(() => {
    setWidth(panelRef.current.offsetWidth * 2)
  }, [panelRef.current])

  return (
    <Drawer isOpen={openData} setIsOpen={setOpenData}>
      <div ref={panelRef} className="w-screen max-w-lg overflow-x-hidden">
        <div
          style={{ width: `${width}px` }}
          className={
            (
              subPanelOpen
                ? "transition-all duration-300 delay-300 ease-in-out translate-x-[-50%]"
                : "transition-all duration-200 ease-out-in translate-x-0"
            )
          }
        >
          <NavPanel>
            <Header title="Geodata" setIsOpen={setOpenData} />
            <ul className="bg-white">
              <li
                className="p-5 flex justify-between items-center border-b border-gray-200 cursor-pointer"
                onClick={() => openPanel('foxtownPanel')}
              >
                <span>Foxtown</span>
                {/* @ts-expect-error */}
                <span className="bg-gray-200 py-1 px-4 rounded-full">{foxtownBoulders.features.length}</span>
              </li>
              <li
                className="p-5 flex justify-between items-center cursor-pointer"
                onClick={() => openPanel('featuresPanel')}
              >
                <span>My Features</span>
                <span className="bg-gray-200 py-1 px-4 rounded-full">{localFeatures.length}</span>
              </li>
            </ul>
          </NavPanel>
          {navPanel === 'foxtownPanel' && (
            <FoxtownPanel setIsOpen={setSubPanelOpen} setDrawerOpen={setOpenData} />
          )}
          {navPanel === 'featuresPanel' && (
            <FeaturesPanel setIsOpen={setSubPanelOpen} setDrawerOpen={setOpenData} />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export interface ISubPanelProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
}

/**
 * Features Panel
 * @param setIsOpen
 * @returns
 */
const FeaturesPanel = ({ setIsOpen, setDrawerOpen }: ISubPanelProps) => {

  const { localFeatures } = useDbContext()

  const colors: any = {
    red: {
      stroke: "rgb(239, 68, 68)",
      fill: "rgba(239, 68, 68, 0.2)"
    },
    green: {
      stroke: "rgb(16, 185, 129)",
      fill: "rgba(16, 185, 129, 0.2)"
    },
    blue: {
      stroke: "rgb(59, 130, 246)",
      fill: "rgba(59, 130, 246, 0.2)"
    },
    purple: {
      stroke: "rgb(147, 51, 234)",
      fill: "rgba(147, 51, 234, 0.2)"
    },
    slate: {
      stroke: "rgb(30, 41, 59)",
      fill: "rgba(30, 41, 59, 0.2)"
    },
  }

  const { map } = useMapContext();

  // geojson parser
  const parser = new GeoJSON()

  return (
    <NavPanel>
      <Header title="My Features" setIsOpen={setIsOpen} backButton="Back" />
      <ul>
        {localFeatures.map((feature: any) => {

          const color = feature.doc.geojson.properties.color
          const type = feature.doc.geojson.geometry.type

          return (
            <li
              key={feature.id}
              className="bg-white border-b border-gray-200 px-5 py-5 flex justify-between cursor-pointer"
              onClick={() => {
                const f = parser.readFeature(feature.doc.geojson)
                const geometry = f.getGeometry()
                const extent = geometry?.getExtent()
                if (extent) {
                  map?.getView().fit(extent, {
                    padding: [100, 100, 100, 100],
                    minResolution: 3,
                    duration: 200
                  })
                }
                setDrawerOpen(false)
              }}
            >
              <div>{feature.doc.geojson.properties.title}</div>
              <div
                style={{
                  color: colors[color].stroke
                }}
              >
                {type === 'Point' && (
                  <HiOutlineLocationMarker className="h-5 w-5" />
                )}
                {type === 'LineString' && (
                  <GiPathDistance className="h-5 w-5" />
                )}
                {type === 'Polygon' && (
                  <TbPolygon className="h-5 w-5" />
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </NavPanel>
  )
}

/**
 * Foxtown Panel
 * @param setIsOpen
 * @returns
 */
const FoxtownPanel = ({ setIsOpen, setDrawerOpen }: ISubPanelProps) => {

  const { map } = useMapContext();

  const foxtownData = Object.entries(
    // @ts-expect-error @todo fix
    groupBy(foxtownBoulders.features, (x) => x.properties.sector)
  );

  // geojson parser
  const parser = new GeoJSON()

  return (
    <NavPanel>
      <Header title="Foxtown Boulders" setIsOpen={setIsOpen} backButton="Back" />
      <div className="mt-3 mx-3 md:mt-5 md:mx-5">
        {foxtownData.map((feature) => {
          return (
            <div className="bg-white rounded-lg mb-3 md:mt-5" key={feature[0]}>
              <div className="px-5 py-3 bg-emerald-600 rounded-tl-lg rounded-tr-lg text-white">
                <h2 className="text-lg">{feature[0]}</h2>
              </div>
              {feature[1].map((feature) => {
                return (
                  <div
                    key={feature.properties.fid}
                    className="text-gray-800 px-5 py-3 border-gray-200 border-b cursor-pointer"
                    onClick={() => {
                      const f = parser.readFeature(feature)
                      const geometry = f.getGeometry()
                      const extent = geometry?.getExtent()
                      if (extent) {
                        map?.getView().fit(extent, {
                          padding: [100, 100, 100, 100],
                          minResolution: 3,
                          duration: 200
                        })
                      }
                      setDrawerOpen(false)
                    }}
                  >
                    <p>{feature.properties.name}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </NavPanel>
  )
}

export default Data;
