// import libs
import { useEffect, useState } from "react"
import { useMapContext } from "../map"
import OLTileLayer from "ol/layer/Tile"

export interface ITileLayer {
  source: any,
  opacity: number,
  visible: boolean,
  name: string,
  zIndex?: number,
}

export const TileLayer = ({ source, opacity, name, zIndex = 10, visible }: ITileLayer) => {

  const { map } = useMapContext()

  // tilelayer
  const [tileLayer,] = useState(() => {
    const tileLayer = new OLTileLayer({
      source: source,
      properties: {
        name: name
      },
    })

    tileLayer.setOpacity(opacity/100)
    tileLayer.setVisible(visible)
    tileLayer.setZIndex(zIndex)

    return tileLayer
  })

  // initial setup
  useEffect(() => {
    if (!map) return;
    map.addLayer(tileLayer)

    return () => {
      if (map) {
        map.removeLayer(tileLayer)
      }
    }
  }, [map])

  // visibility
  useEffect(() => {
    if (!map) return;
    tileLayer.setVisible(visible)
  }, [visible])

  // opacity
  useEffect(() => {
    if (!map) return;
    tileLayer.setOpacity(opacity/100)
  }, [opacity])

  return null
}