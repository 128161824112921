// import libs
import { useEffect, useState } from "react";
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector";
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Point } from "ol/geom";
import { useMapContext } from "../map";

export const LocationLayer = () => {

  const {
    locationEnabled,
    followLocation,
    setHeading,
    map
  } = useMapContext()

  const [locationLayer, setLocationLayer] = useState<any>()
  const [geolocation, setGeolocation] = useState<any>()

  /**
   * Initial location setup
   */
  useEffect(() => {
    if (!map) return;

    // accuracy feature
    const accuracyFeature = new Feature({
      name: 'accuracyFeature'
    });

    // position feature
    const positionFeature = new Feature({
      name: 'positionFeature'
    });

    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    // features source
    const vectorSource = new VectorSource({
      features: [accuracyFeature, positionFeature]
    })

    // location layer
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      properties: {
        name: 'locationLayer'
      },
      visible: false,
      zIndex: 500
    })

    setLocationLayer(vectorLayer)

    const geoloc = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: map.getView().getProjection()
    })

    setGeolocation(geoloc)
  }, [map])

  /**
   * Add location layer to map
   */
  useEffect(() => {
    if (!map) return

    if (locationLayer) {
      map.addLayer(locationLayer)
    }

    return () => {
      if (locationLayer) {
        map.removeLayer(locationLayer)
      }
    }
  }, [locationLayer])

  /**
   * Geolocation Listener
   */
  useEffect(() => {
    if (!map || !geolocation) return;

    let accuracyFeature: any;
    let positionFeature: any;

    // get features
    const features = locationLayer.getSource().getFeatures()
    features.forEach((feature: any) => {
      const name = feature.get('name')
      if (name === 'accuracyFeature') {
        accuracyFeature = feature
      }
      if (name === 'positionFeature') {
        positionFeature = feature
      }
    })

    /**
     * Location Enabled
     */
    if (locationEnabled && !followLocation) {
      locationLayer.setVisible(true)
      geolocation.setTracking(true)

      // set accuracy geometry
      geolocation.on('change:accuracyGeometry', () => {
        accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
      });

      // set position geometry
      geolocation.on('change:position', () => {
        const coordinates = geolocation.getPosition()
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null)

        const heading = geolocation.getHeading()
        if (heading) {
          setHeading(heading)
        }
      })

      // headding
      geolocation.on('change:heading', (evt: any) => {
        const heading = geolocation.getHeading()
        if (heading) {
          setHeading(heading)
        }
      })
    }

    /**
     * Follow Location
     */
    if (locationEnabled && followLocation) {
      const coordinates = geolocation.getPosition()
      map.getView().setCenter(coordinates)
      map.getView().setZoom(17)

      // set position geometry
      geolocation.on('change:position', () => {
        const coordinates = geolocation.getPosition()
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null)

        const zoom = map.getView().getZoom()
        map.getView().setCenter(coordinates)
        map.getView().setZoom(zoom || 17)
      })

      // headding
      geolocation.on('change:heading', (evt: any) => {
        const heading = geolocation.getHeading()
        if (heading) {
          setHeading(heading)
        }
      })
    }

    /**
     * Location Disabled
     */
    if (!locationEnabled) {
      geolocation.setTracking(false)
      locationLayer.setVisible(false)
    }

  }, [locationEnabled, followLocation])

  return null
}