import type Map from "ol/Map"
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { getLength } from 'ol/sphere';

export const foxtownStyle = (feature: any, map: Map) =>{

  const zoom = map.getView().getZoom() || 14
  const type = feature.getGeometry().getType()

  const labelStyle = new Style({
    text: new Text({
      font: '13px',
      overflow: true,
      fill: new Fill({
        color: '#222'
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3
      }),
      textAlign: 'center',
      text: zoom > 13.5 ? feature.get('title') : '',
      offsetY: 15
    }),
  })

  const zIndex10Style = new Style({
    zIndex: 10
  })

  const zIndex15Style = new Style({
    zIndex: 15
  })

  const zIndex20Style = new Style({
    zIndex: 20
  })

  const colors: any = {
    red: {
      stroke: "rgb(239, 68, 68)",
      fill: "rgba(239, 68, 68, 0.2)"
    },
    green: {
      stroke: "rgb(16, 185, 129)",
      fill: "rgba(16, 185, 129, 0.2)"
    },
    blue: {
      stroke: "rgb(59, 130, 246)",
      fill: "rgba(59, 130, 246, 0.2)"
    },
    purple: {
      stroke: "rgb(147, 51, 234)",
      fill: "rgba(147, 51, 234, 0.2)"
    },
    slate: {
      stroke: "rgb(30, 41, 59)",
      fill: "rgba(30, 41, 59, 0.2)"
    },
  }

  const color = colors[feature.get('color')]

  switch(type) {
    case 'Point':
      const bulletStyle = new Style({
        image: new CircleStyle({
          radius: zoom > 13.5 ? 5 : 3,
          fill: new Fill({ color: color.stroke }),
          stroke: new Stroke({ color: "#fff", width: 1 }),
        }),
      })
      return [labelStyle, bulletStyle, zIndex20Style]

    case 'LineString':
      const geom = feature.getGeometry()
      const length = getLength(geom)
      const distanceKm = (length / 1000).toFixed(2) + ' km'
      const distanceM = (length*0.0006213712).toFixed(2) + ' mi'

      const lineLabelStyle = new Style({
        text: new Text({
          font: '13px',
          overflow: true,
          fill: new Fill({
            color: '#222'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3
          }),
          textAlign: 'center',
          text: zoom > 13.5 ? `${feature.get('title')}\n ${distanceKm} / ${distanceM}` : '',
          offsetY: 15
        }),
      })

      const lineStyle = new Style({
        stroke: new Stroke({
          color: color.stroke,
          width: 2,
          lineDash: [4],

        }),
      })

      return [lineLabelStyle, lineStyle, zIndex15Style]

    case 'Polygon':

      let polygonText: any;
      let fontStyle = "bold 14px sans-serif"
      const type = feature.get('color')

      switch (type) {
        case 'green':
          polygonText = feature.get('title').toUpperCase()
          break;
        default:
          fontStyle = "bold 10px sans-serif"
          polygonText = zoom > 13.5 ? feature.get('title').toUpperCase() : ''
      }

      const polygonLabelStyle = new Style({
        text: new Text({
          font: fontStyle,
          overflow: true,
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: color.stroke,
            width: 2
          }),
          text: polygonText
        })
      })

      const polygonStyle = new Style({
        stroke: new Stroke({
          color: color.stroke,
          width: 2,
        }),
        fill: new Fill({
          color: color.fill,
        }),
      })
      return [polygonLabelStyle, polygonStyle, zIndex10Style];

    default:
      return [labelStyle]
  }

}
