// import libs
import React, { useState } from "react"
import { useDbContext } from "../db"
import { useMapContext } from "../map"
import axios from "axios"

// import components
import { Box, Section } from "../shared-ui"

export const AdminBox = () => {

  const { dbId, currentDbName, setCurrentDb } = useDbContext()
  const { setFoxtownHash, foxtownRep, setFoxtownRep } = useMapContext()

  const [adminPass, setAdminPass] = useState<string>("")
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState<string>("")

  const checkPassword = () => {
    axios.post('/api/foxtown', {
      password: adminPass
    }).then((response) => {
      if (response.data.match) {
        setError("")
        setFoxtownHash(response.data.hash)
        setFoxtownRep(true)
        setDisabled(true)
      } else {
        setError("Incorrect password")
        setFoxtownHash("")
        setFoxtownRep(true)
      }
    }).catch((error) => {
      setError(error.message)
    })
  }

  if (dbId !== "foxtown") return null;

  return (
    <Box title={foxtownRep ? "Admin Editing" : "Admin Login"}>
      <Section>
        {!foxtownRep && (
          <div>
            <div className="mt-3">
              <input
                type="password"
                placeholder="Admin Password"
                className="input w-full max-w-xs mr-3 mb-3"
                value={adminPass}
                onChange={(e) => {
                  setAdminPass(e.target.value)
                }}
                disabled={disabled}
              />
              {disabled && (
                <button
                  className="btn bg-slate-900 text-white mr-3"
                  onClick={() => setDisabled(false)}
                >
                  Edit
                </button>
              )}
              {!disabled && (
                <button
                  className="btn btn-success mr-3"
                  onClick={() => {
                    // save the password
                    checkPassword()
                  }}
                >
                  Save
                </button>
              )}
            </div>
            {error !== "" && (
              <p className="text-xs italic bg-red-400 text-white px-5 py-2 rounded-md">
                {error}
              </p>
            )}
          </div>
        )}
        {foxtownRep && (
          <div>
            <p>You are logged in as a Foxtown Admin. Which map would you like to modify while editing?</p>
            <div className="space-x-5 mt-3 flex items-center">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  className="radio"
                  name="map"
                  checked={currentDbName === 'foxtown' ? true : false}
                  onChange={() => setCurrentDb('foxtown')}
                />
                <span className="text-md">Foxtown Map</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  className="radio"
                  name="map"
                  checked={currentDbName === 'features' ? true : false}
                  onChange={() => setCurrentDb('features')}
                />
                <span className="text-md">Personal Map</span>
              </label>
            </div>
          </div>
        )}
      </Section>
    </Box>
  )
}