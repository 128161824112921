// import libs
import React, { FC, useState, useEffect } from "react";
import { DbContext, defaultState } from "./db-context";
import PouchDB from "pouchdb"
import { isBrowser } from "../../utils";
import GeoJSON from "ol/format/GeoJSON";
import { v4 as uuidv4 } from "uuid";
import { getCenter } from "ol/extent"
import { transform } from "ol/proj"
import { toStringXY } from "ol/coordinate"

// app provider
export const DbProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {

  // random string
  const generateID = (length: number = 8) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // get remote db
  const getRemoteDB = (user: string, pass: string, id: string) => {
    const url = `https://${user}:${pass}@db.foxtownboulders.com/features-${id}`;
    return new PouchDB(url);
  };

  // database id
  const [dbId, setDbId] = useState<string>(() => {
    let localDbId = localStorage.getItem("local_db_id");
    if (localDbId) {
      return localDbId;
    } else {
      localDbId = generateID(8);
      localStorage.setItem("local_db_id", localDbId);
      return localDbId;
    }
  });

  // local db
  const [localDb,] = useState<PouchDB.Database|null>(() => {
    if (isBrowser) {
      return new PouchDB("local-features");
    }
    return null;
  });

  // remote db
  const [remoteDb,] = useState<PouchDB.Database>(() => {
    const user = process.env.GATSBY_COUCHDB_USER || "";
    const pass = process.env.GATSBY_COUCHDB_PASS || "";
    const remoteDb: PouchDB.Database = getRemoteDB(user, pass, dbId);
    return remoteDb;
  });

  // foxtown remote db
  const [foxtownRemoteDb,] = useState<PouchDB.Database>(() => {
    const user = process.env.GATSBY_COUCHDB_USER || "";
    const pass = process.env.GATSBY_COUCHDB_PASS || "";
    const url = `https://${user}:${pass}@db.foxtownboulders.com/foxtown-data`;
    return new PouchDB(url);
  })

  // foxtown local db
  const [foxtownDb,] = useState<PouchDB.Database|null>(() => {
    if (isBrowser) {
      return new PouchDB("foxtown-data")
    }
    return null
  })

  // offline tiles db
  const [offlineTilesDb,] = useState<PouchDB.Database | null>(() => {
    if (isBrowser) {
      return new PouchDB("offline-tiles");
    }
    return null;
  });
  const [tileCount, setTileCount] = useState(defaultState.tileCount)

  // sync handler
  const [localSyncHandler, setLocalSyncHandler] = useState<unknown>(null);
  const [foxtownSyncHandler, setFoxtownSyncHandler] = useState<unknown>(null)

  // data
  const [localFeatures, setLocalFeatures] = useState(defaultState.localFeatures)
  const [foxtownFeatures, setFoxtownFeatures] = useState(defaultState.foxtownFeatures)

  // editing
  const [currentDbName, setCurrentDbName] = useState(defaultState.currentDbName)
  const [currentDb, setCurrentDbFn] = useState<PouchDB.Database|null>(() => {
    if (isBrowser) {
      return currentDbName === 'foxtown' ? foxtownDb : localDb
    }
    return null
  })
  const setCurrentDb = (dbname: string) => {
    if (dbname === 'foxtown') {
      setCurrentDbName('foxtown')
      setCurrentDbFn(foxtownDb)
    } else {
      setCurrentDbName('features')
      setCurrentDbFn(localDb)
    }
  }

  // get features
  const getFeatures = async (dbname: string) => {

    let currentDb: any;
    if (dbname === 'features') {
      currentDb = localDb
    }
    if (dbname === 'foxtown') {
      currentDb = foxtownDb
    }

    let featuresData: any;
    let featuresTracker: any = [];

    return currentDb
      ?.allDocs({
        include_docs: true,
      })
      .then((res: any) => {
        featuresData = res.rows.map((row: any) => {
          return row.doc;
        });
        featuresData.forEach((result: any) => {
          featuresTracker.push({ id: result._id, doc: result });
        });
        if (dbname === 'features') {
          setLocalFeatures(featuresTracker);
        }
        if (dbname === 'foxtown') {
          setFoxtownFeatures(featuresTracker);
        }
      });
  };

  // add feature
  const addFeature = (evt: any) => {

    // get geojson
    const parser = new GeoJSON()
    const feature = parser.writeFeatureObject(evt.feature)

    // db doc
    let doc: any = {};

    // build the title
    const center = getCenter(evt.feature.getGeometry().getExtent())
    const coords = transform(JSON.parse(JSON.stringify(center)), 'EPSG:3857', 'EPSG:4326')
    const title = toStringXY(coords, 3)

    // build the doc id
    doc._id = uuidv4()

    // doc geojson
    doc.geojson = feature;
    doc.geojson.properties = {
      title: title,
      color: 'purple'
    };

    currentDb?.put(doc)
      .then((response) => {
        console.log("feature added: ", response.id);
        getFeatures(currentDbName);
      })
      .catch((err) => {
        console.log("feature error: ", err.message);
      });
  };

  // update feature
  const updateFeature = (feature: any) => {

    currentDb?.put(feature)
      .then((response) => {
        console.log("feature updated: ", response.id);
        getFeatures(currentDbName);
      })
      .catch((err) => {
        console.log("freature error: ", err.message);
      });
  };

  // remove feature
  const removeFeature = (doc: any) => {

    currentDb?.remove(doc)
      .then((response) => {
        console.log("feature deleted: ", response.id);
        getFeatures(currentDbName);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const resetDatabase = () => {
    console.log('localDb: resetting database')
    console.log('features: ', localFeatures)
    localFeatures.forEach((feature: any) => {
      //removeFeature(feature.doc)
    })
  }

  const resetOfflineTilesDb = () => {
    if (!offlineTilesDb) return;
    offlineTilesDb.allDocs({
      include_docs: true,
    })
      .then((docs) => {
        docs.rows.forEach((tile: any) => {
          offlineTilesDb.remove({ _id: tile.doc._id, _rev: tile.doc._rev })
            .then((response) => {
              console.log('offlineTilesDb: deleted ', response.id)
            })
            .catch((error) => {
              console.log(error.message)
            })
        })
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  // set sync id
  const setSyncId = (id: string) => {
    localStorage.setItem("local_db_id", id);

    if (localSyncHandler) {
      // @ts-expect-error
      localSyncHandler.cancel();
      localDb?.destroy().then(() => {
        location.reload();
      });
    }
    setDbId(id);
  };

  /**
   * Sync Local Db
   */
  useEffect(() => {
    if (!localDb) return

    console.log('localDb: starting sync')
    setLocalSyncHandler(() => {
      return localDb
        ?.sync(remoteDb, {
          live: true,
          retry: true,
        })
        .on("change", (change) => {
          // yo, something changed!
          console.log("localDb change: ", change.direction, change.change.start_time);
          getFeatures('features')
        })
        .on("denied", (info) => {
          console.log(info);
        })
        .on("paused", () => {
          getFeatures('features');
        })
        .on("complete", (info) => {
          console.log("localDb pushed: ", info.push?.status, info.push?.end_time);
          console.log("localDb pulled: ", info.pull?.status, info.pull?.end_time);
          getFeatures('features');
        })
        .on("error", (err) => {
          // totally unhandled error (shouldn't happen)
          console.log(err);
        });
    });
  }, [localDb]);

  /**
   * Sync Foxtown DB
   */
  useEffect(() => {
    if (!foxtownDb) return

    console.log('foxtownDb: starting sync')
    setFoxtownSyncHandler(() => {
      return foxtownDb
        ?.sync(foxtownRemoteDb, {
          live: true,
          retry: true,
        })
        .on("change", (change) => {
          // yo, something changed!
          console.log("foxtownDb change: ", change.direction, change.change.start_time);
          getFeatures('foxtown')
        })
        .on("denied", (info) => {
          console.log(info);
        })
        .on("paused", () => {
          getFeatures('foxtown');
        })
        .on("complete", (info) => {
          console.log("foxtownDb pushed: ", info.push?.status, info.push?.end_time);
          console.log("foxtownDb pulled: ", info.pull?.status, info.pull?.end_time);
          getFeatures('foxtown');
        })
        .on("error", (err) => {
          // totally unhandled error (shouldn't happen)
          console.log(err);
        });
    });
  }, [foxtownDb])

  useEffect(() => {
    if (!offlineTilesDb) return;

    offlineTilesDb.info()
      .then((info) => {
        setTileCount(info.doc_count)
      })

    // track changes
    offlineTilesDb.changes({
      since: 'now',
      live: true,
    }).on('change', (change) => {
      offlineTilesDb.info().then((info) => {
        setTileCount(info.doc_count)
      })
    }).on('error', (err) => {
      console.log(err)
    })

  }, [offlineTilesDb])

  return (
    <DbContext.Provider
      value={{
        // database
        localDb,
        remoteDb,
        foxtownDb,
        offlineTilesDb,
        tileCount, setTileCount,
        resetOfflineTilesDb,
        dbId, setSyncId,
        // features
        localFeatures,
        foxtownFeatures,
        addFeature,
        updateFeature,
        removeFeature,
        resetDatabase,
        // editing
        currentDbName, setCurrentDb
      }}
    >
      {children}
    </DbContext.Provider>
  );
};
