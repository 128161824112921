// import libs
import { createContext, useContext, Dispatch, SetStateAction } from "react";

export interface IDbContext {
  // database
  localDb: PouchDB.Database|null,
  remoteDb: PouchDB.Database|null
  foxtownDb: PouchDB.Database|null,
  offlineTilesDb: PouchDB.Database|null,
  dbId: string,
  setSyncId: (id: string) => void,
  // features
  localFeatures: any,
  foxtownFeatures: any,
  addFeature: (feature: any) => void,
  updateFeature: (feature: any) => void,
  removeFeature: (feature: any) => void,
  resetDatabase: () => void,
  tileCount: number,
  setTileCount: Dispatch<SetStateAction<number>>
  resetOfflineTilesDb: () => void,
  // editing
  currentDbName: string,
  setCurrentDb: (dbname: string) => void
}

export const defaultState = {
  // database
  localDb: null,
  remoteDb: null,
  foxtownDb: null,
  offlineTilesDb: null,
  dbId: "",
  setSyncId: () => null,
  // features
  localFeatures: [],
  foxtownFeatures: [],
  addFeature: () => null,
  updateFeature: () => null,
  removeFeature: () => null,
  resetDatabase: () => null,
  tileCount: 0,
  setTileCount: () => null,
  resetOfflineTilesDb: () => null,
  // editing
  currentDbName: "features",
  setCurrentDb: () => null,
}

// app context
export const DbContext = createContext<IDbContext>(defaultState);

export const useDbContext = () => useContext(DbContext);
