// import libs
import React from "react";

// import components
import { Dropdown } from "../shared-ui";

export const Rules = () => {
  return (
    <Dropdown title="Pigsteak's Guidelines">
      <ol className="space-y-3 text-sm list-decimal ml-5">
        <li>
          Leave the hammocks at home. If you need to rest, take a nap on one of
          the plush crash pads you carried in.
        </li>
        <li>
          No music on speakers or loud enough to infringe on others. Out solo,
          knock yourself out. But if there is another car in the area, that
          means someone else is out having a sesh too.
        </li>
        <li>
          Pack it in, pack it out. We are trying to pick up garbage and litter
          that was there before we even visited, so please help with this. Bring
          gloves and a trash bag, and spend 5 minutes beautifying.
        </li>
        <li>
          Almost always carry snips or small hand saw to cut brush back. If it
          rubs your legs or snags your pack, the next person will thank you for
          a minor trim. The main trails are already in, but they grow up
          annually. be prepared to do some maintenance every visit.
        </li>
        <li>
          Save the alcohol and illegal vapes for your personal time at home.
        </li>
        <li>
          Be respectful and friendly to the locals. Introduce yourself. tell
          them what you are doing. They aren't used to having weirdos traipsing
          in their backyards with bed mattresses strapped to their back. All
          published private areas, we have explicit permission to be there, but
          a neighbor may not know that.
        </li>
        <li>
          Don't complain about dirty holds. Bring a boar's hair brush to clean
          it up. Learn how to use a nylon or wire brush to clean moss that grows
          like wildfire in KY (there is an art here so you don't remove the
          patina. please ask and have someone show you).
        </li>
        <li>
          Be prepared to explore. We've spent tens of thousands of hours walking
          aimlessly in the county finding these rigs. If you aren't ready for an
          outdoor experience, then you'll be disappointed.
        </li>
        <li>
          Use all the online sources that are available. Kaleb and Ray both have
          killer apps that work offline. I am not even going to provide links
          here. Let's see who takes initiative.
        </li>
        <li>Spread out and don't hibernate under one zone or project.</li>
      </ol>
    </Dropdown>
  );
};
