// import libs
import React, { Dispatch, SetStateAction } from "react";
import { useSwipeable } from "react-swipeable";

export interface IBottomDrawer {
  children: React.ReactNode,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const BottomDrawer = ({ children, isOpen, setIsOpen }: IBottomDrawer) => {
  const handlers = useSwipeable({
    onSwipedDown: () => {
      setIsOpen(false);
    },
  });

  return (
    <div
      {...handlers}
      className={
        "z-[10000] absolute bottom-0 w-screen max-w-lg cursor-default transition-all transform " +
        (isOpen ? "translate-y-0" : "translate-y-[100%]")
      }
    >
      {children}
    </div>
  );
};
