// import libs
import * as React from "react";
import type { HeadProps } from "gatsby";
import { isBrowser } from "../utils";

// import components
import { Map } from "../screens";
import { Seo } from "../components/seo";
import { DbProvider } from "../components/db";
import { MapProvider } from "../components/map";

const IndexPage = () => {

  if (!isBrowser) return null

  const extent = [-9372601.869464051, 4489258.15171887, -9338759.312296428, 4514730.471946866]

  return (
    <div className="h-screen w-screen overflow-hidden">
      <DbProvider>
        <MapProvider extent={extent}>
          <Map />
        </MapProvider>
      </DbProvider>
    </div>
  )
}

export default IndexPage;

export const Head = (props: HeadProps) => <Seo {...props} />;
