// import libs
import { useEffect, useState } from "react"
import { useMapContext } from "../map"
import { useDbContext } from "../db"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import Draw from "ol/interaction/Draw"

export const DrawLayer = ({ zIndex = 200 }) => {

  const { map, editMode } = useMapContext()
  const { addFeature } = useDbContext()

  const [drawLayer, setDrayLayer] = useState<any>()
  const [drawSource, setDrawSource] = useState<any>()

  useEffect(() => {
    if (!map) return;

    const vectorSource = new VectorSource({ wrapX: false })
    setDrawSource(vectorSource)

    let vectorLayer = new VectorLayer({
      source: vectorSource,
      properties: {
        name: 'draw-layer'
      },
    })

    map.addLayer(vectorLayer)

    setDrayLayer(vectorLayer)

    vectorLayer.setZIndex(zIndex)

    vectorLayer.getSource()?.on('addfeature', (evt) => {
      vectorSource.clear()
    })

    return () => {
      if (map) {
        map.removeLayer(vectorLayer)
      }
    }
  }, [map])

  useEffect(() => {
    if (!map) return;

    let draw: any;

    if (editMode !== 'None') {
      draw = new Draw({
        source: drawSource,
        // @ts-expect-error
        type: editMode,
        freehand: false,
      })

      draw.on('drawend', (evt: any) => {
        addFeature(evt)
      })
      map.addInteraction(draw)
    }

    return () => {
      if (draw) {
        map.removeInteraction(draw)
      }
    }
  }, [drawLayer, drawSource, editMode])

  return null
}