// import libs
import React from "react"

export interface INavPanel {
  children: React.ReactNode
}

export const NavPanel = ({ children }: INavPanel) => {

  return(
    <div className="w-screen max-w-lg float-left">{children}</div>
  )
}