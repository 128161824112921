// import libs
import React, { useState } from "react";
import { IDropdownProps } from "./shared-ui";

// import components
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

export const Dropdown = ({ title, children }: IDropdownProps) => {
  const [active, setActive] = useState(false);

  return (
    <div className="px-3 pt-3 md:px-5 md:pt-5">
      <div
        className={
          "p-5 bg-white rounded-tl-lg rounded-tr-lg border-b border-slate-200 flex flex-row justify-between items-center cursor-pointer transform " +
          (active ? "" : "rounded-bl-lg rounded-br-lg")
        }
        onClick={() => setActive(!active)}
      >
        <span>{title}</span>

        {active && <TiArrowSortedUp size={20} />}
        {!active && <TiArrowSortedDown size={20} />}
      </div>
      <div
        className={
          "rounded-bl-lg rounded-br-lg px-5 " +
          (active
            ? "transition-height duration-200 ease-out-in py-5 bg-white"
            : "overflow-hidden h-0")
        }
      >
        {children}
      </div>
    </div>
  );
};
