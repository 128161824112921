// import libs
import React from "react";

export interface IFoxtownIconProps {
  size: number,
  className?: string
}

export const FoxtownIcon = ({ size, className }: IFoxtownIconProps) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 511.142 511.142"
      height={size}
      width={size}
      viewBox="0 0 511.142 511.142"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <g>
        <path d="m506.332 0-106.004 52.008h-99.704l-106.004-52.008 13.116 154.43 17.565 40.903-62.491 86.824v-72.619l-158-79v123.532l117.359 257.071h244.123v-61.148l126.925-295.563zm-343.522 481.142v-106.998l79.217 106.998zm-128-233.596v-68.467l48.428 24.214-40.735 61.103zm75.469-30.733 22.531 11.265v95.759 138.375l-75.653-165.715zm56.102 111.737 72.733-101.052 97.178 226.294v27.35h-56.938zm62.618-278.267 43.799 21.489-38.324 42.978zm237.48 64.467-38.324-42.978 43.799-21.489zm-69.391-32.742 59.76 67.017-106.372 86.76-106.372-86.76 59.76-67.017zm-46.611 328.786-90.082-209.769 90.082 73.473 90.082-73.473z" />
      </g>
    </svg>
  );
};

export default FoxtownIcon;
