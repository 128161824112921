// import libs
import React, { useState, useEffect } from "react";
import { isBrowser } from "../../utils";
import { useDbContext } from "../db";
import { useAppContext } from "../app";

// import components
import { Drawer, Header, Box, Section } from "../shared-ui";
import { AdminBox } from "../admin";

export const Sync = () => {
  const { openSync, setOpenSync } = useAppContext();

  const { dbId, setSyncId, localFeatures, resetDatabase, tileCount, setTileCount, resetOfflineTilesDb } = useDbContext();

  // offline state
  const [resetDbAlert, setResetDbAlert] = useState(false);
  const [resetTilesAlert, setResetTilesAlert] = useState(false);

  const [idDisabled, setIdDisabled] = useState(true);
  const [syncId, setLocalSyncId] = useState(dbId);

  // const [storage, setStorage] = useState<any>({
  //   quota: 0,
  //   usage: 0
  // })

  // Edit sync id
  const editSyncId = (value: string) => {
    setLocalSyncId(value.toLocaleLowerCase().replace(/[^a-z]/g, ''));
  };

  // storage estimate
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (isBrowser) {
  //       navigator.storage.estimate()
  //         .then((estimate) => {
  //           setStorage(estimate)
  //         })
  //     }
  //   }, 3000)

  //   return () => clearTimeout(timer)
  // }, [])

  return (
    <Drawer isOpen={openSync} setIsOpen={setOpenSync}>
      <Header title="Sync" setIsOpen={setOpenSync} />
      <Box title="Cloud Sync">
        <Section>
          <p className="text-sm">
            Your Cloud Sync Code. Write this down somewhere incase you need to
            sync your markers back to the app.{" "}
            <em>Create your own using lowercase letters only.</em>
          </p>
          <div className="mt-3">
            <input
              type="text"
              pattern="[a-z]"
              placeholder="Sync ID"
              className="input w-full max-w-xs mr-3 mb-3 foxtown-sync-box"
              value={syncId}
              onChange={(e) => editSyncId(e.target.value)}
              disabled={idDisabled}
            />
            {idDisabled && (
              <button
                className="btn bg-slate-900 text-white mr-3"
                onClick={() => setIdDisabled(false)}
              >
                Edit
              </button>
            )}
            {!idDisabled && (
              <button
                className="btn btn-success mr-3"
                onClick={() => {
                  setSyncId(syncId);
                  setIdDisabled(true);
                }}
              >
                Save
              </button>
            )}
          </div>
          {!idDisabled && (
            <p className="text-xs italic bg-red-400 text-white px-5 py-2 rounded-md">
              Changing your sync id will delete your local markers database and
              restart the app. If you have synced to another id in the past,
              your synced markers will be imported.
            </p>
          )}
        </Section>
      </Box>

      <Box title="Offline Data">
        <Section>
          {resetDbAlert && (
            <div className="alert shadow-lg mb-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="stroke-info flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span className="text-xs">
                  Are you sure you want to delete your markers? They cannot be
                  recovered.
                </span>
              </div>
              <div className="flex-none">
                <button
                  className="btn btn-sm btn-ghost"
                  onClick={() => setResetDbAlert(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm bg-red-400 border-0"
                  onClick={() => {
                    resetDatabase()
                    setResetDbAlert(false);
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          )}

          {resetTilesAlert && (
            <div className="alert shadow-lg mb-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="stroke-info flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span className="text-xs">
                  Are you sure you want to delete your tiles? You will have to
                  download them again.
                </span>
              </div>
              <div className="flex-none">
                <button
                  className="btn btn-sm btn-ghost"
                  onClick={() => setResetTilesAlert(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm bg-red-400 border-0"
                  onClick={() => {
                    resetOfflineTilesDb()
                    setResetTilesAlert(false);
                    setTileCount(0);
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center">
            <p>Total Markers: {localFeatures.length}</p>
            <button
              className="btn btn-sm btn-outline btn-ghost"
              onClick={() => {
                setResetDbAlert(true);
              }}
            >
              Reset
            </button>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p>Tile Count: {tileCount}</p>
            <button
              className="btn btn-sm btn-outline btn-ghost"
              onClick={() => {
                setResetTilesAlert(true);
              }}
            >
              Reset
            </button>
          </div>

          {/* <div className="flex justify-between items-center mt-3">
            <p>Storage Used: {formatBytes(storage.usage)}</p>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p>Storage Quota: {formatBytes(storage.quota)}</p>
          </div> */}

        </Section>
      </Box>

      <AdminBox />

    </Drawer>
  );
};

export default Sync;
