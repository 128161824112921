// import libs
import React, { useRef, useState, useEffect } from "react"
import { MapContext, defaultState } from "./map-context"
import * as ol from "ol"
import { defaults as defaultInteractions } from "ol/interaction"
import axios from "axios"

// import components
import { Navbar } from "../navbar"
import { Menu, Settings, Sync, Data } from "../drawers";
// import { Loader } from "../loader"
import { isBrowser } from "../../utils"
import { toInteger } from "lodash"
import { Joyride } from "../joyride"

export interface IMapProvider {
  children: React.ReactNode
  zoom?: number
  center?: any,
  extent: any
}

export const MapProvider = ({ children, zoom, center, extent }: IMapProvider) => {

  // map reference
  const mapRef = useRef<any>()

  // map state
  const [map, setMap] = useState<ol.Map | null>(defaultState.map)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [mapLoadMessage, setMapLoadMessage] = useState(defaultState.mapLoadMessage)

  // sources
  const [foxtownSource, setFoxtownSource] = useState(defaultState.foxtownSource)
  // const [extentSet, setExtentSet] = useState(false)

  // admin
  const [foxtownRep, setFoxtownRep] = useState(defaultState.foxtownRep)
  const [foxtownPass] = useState(defaultState.foxtownPass)
  const [foxtownHash] = useState<string>("")

  const setFoxtownPass = (pass: string) => {
    if (isBrowser) {
      localStorage.setItem('foxtownHash', pass.toString())
    }
  }
  const setFoxtownHash = (hash: string) => {
    if (isBrowser) {
      localStorage.setItem('foxtownHash', hash.toString())
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const foxtownHash = localStorage.getItem('foxtownHash') || ""
      axios.post("/api/hash-check", {
        hash: foxtownHash
      }).then((response) => {
        if (response.data.match) {
          setFoxtownRep(response.data.match)
        }
      }).catch((error) => {
        setFoxtownRep(false)
        console.log(error.message)
      })
    }
  }, [])

  // tiles state
  const [lidarEnabled, setLidarEnabledFn] = useState(defaultState.lidarEnabled)
  const [lidarOpacity, setLidarOpacityFn] = useState(defaultState.lidarOpacity)
  const [satelliteEnabled, setSatelliteEnabledFn] = useState(defaultState.satelliteEnabled)
  const [satelliteOpacity, setSatelliteOpacityFn] = useState(defaultState.satelliteOpacity)
  const [usfsEnabled, setUsfsEnabledFn] = useState(defaultState.usfsEnabled)
  const [usfsOpacity, setUsfsOpacityFn] = useState(defaultState.usfsOpacity)

  const setLidarEnabled = (enabled: boolean) => {
    setLidarEnabledFn(enabled)
    if (isBrowser) {
      localStorage.setItem('lidarEnabled', enabled.toString())
    }
  }
  const setLidarOpacity = (opacity: number) => {
    setLidarOpacityFn(opacity)
    if (isBrowser) {
      localStorage.setItem('lidarOpacity', opacity.toString())
    }
  }
  const setSatelliteEnabled = (enabled: boolean) => {
    setSatelliteEnabledFn(enabled)
    if (isBrowser) {
      localStorage.setItem('satelliteEnabled', enabled.toString())
    }
  }
  const setSatelliteOpacity = (opacity: number) => {
    setSatelliteOpacityFn(opacity)
    if (isBrowser) {
      localStorage.setItem('satelliteOpacity', opacity.toString())
    }
  }
  const setUsfsEnabled = (enabled: boolean) => {
    setUsfsEnabledFn(enabled)
    if (isBrowser) {
      localStorage.setItem('usfsEnabled', enabled.toString())
    }
  }
  const setUsfsOpacity = (opacity: number) => {
    setUsfsOpacityFn(opacity)
    if (isBrowser) {
      localStorage.setItem('usfsOpacity', opacity.toString())
    }
  }

  useEffect(() => {
    if (!map) return

    if (isBrowser) {
      const localLidarEnabled = localStorage.getItem('lidarEnabled')
      const localLidarOpacity = localStorage.getItem('lidarOpacity')
      const localSatelliteEnabled = localStorage.getItem('satelliteEnabled')
      const localSatelliteOpacity = localStorage.getItem('satelliteOpacity')
      const localUsfsEnabled = localStorage.getItem('usfsEnabled')
      const localUsfsOpacity = localStorage.getItem('usfsOpacity')

      if (localLidarEnabled) {
        setLidarEnabledFn(localLidarEnabled === 'true')
      } else {
        localStorage.setItem('lidarEnabled', defaultState.lidarEnabled.toString())
      }

      if (localLidarOpacity) {
        setLidarOpacityFn(toInteger(localLidarOpacity))
      } else {
        localStorage.setItem('lidarOpacity', defaultState.lidarOpacity.toString())
      }

      if (localSatelliteEnabled) {
        setSatelliteEnabledFn(localSatelliteEnabled === 'true')
      } else {
        localStorage.setItem('satelliteEnabled', defaultState.satelliteEnabled.toString())
      }

      if (localSatelliteOpacity) {
        setSatelliteOpacityFn(toInteger(localSatelliteOpacity))
      } else {
        localStorage.setItem('satelliteOpacity', defaultState.satelliteOpacity.toString())
      }

      if (localUsfsEnabled) {
        setUsfsEnabledFn(localSatelliteEnabled === 'true')
      } else {
        localStorage.setItem('usfsEnabled', defaultState.usfsEnabled.toString())
      }

      if (localUsfsOpacity) {
        setUsfsOpacityFn(toInteger(localUsfsOpacity))
      } else {
        localStorage.setItem('usfsOpacity', defaultState.usfsOpacity.toString())
      }

    }
  }, [map])

  // edit state
  const [editMode, setEditMode] = useState(defaultState.editMode)

  // location state
  const [locationEnabled, setLocationEnabled] = useState(defaultState.locationEnabled)
  const [followLocation, setFollowLocation] = useState(defaultState.followLocation)
  const [heading, setHeading] = useState(defaultState.heading)

  // controls
  const [editFeatures, setEditFeatures] = useState(defaultState.editFeatures)

  // details
  const [detailsModal, setDetailsModal] = useState(defaultState.detailsModal)

  // toggle follow location
  const toggleFollowLocation = () => {
    // step 1: location is not enabled
    if (!locationEnabled) {
      setLocationEnabled(true)
    }
    // step 2: location is enabled, but follow is not
    if (locationEnabled && !followLocation) {
      // map?.flyTo(coordinates, 16)
      setFollowLocation(true)
    }
    // step 3: disable
    if (locationEnabled && followLocation) {
      // map?.stopLocate();
      setLocationEnabled(false)
      setFollowLocation(false)
    }
  }

  // initialize map
  useEffect(() => {
    if (!mapRef.current) return;

    let options = {
      view: new ol.View({
        zoom: 0,
        center: [0, 0],
        enableRotation: false
      }),
      layers: [],
      controls: [],
      overlays: [],
      interactions: defaultInteractions({
        doubleClickZoom: false
      })
    }

    let mapObject = new ol.Map(options)

    mapObject.setTarget(mapRef.current)

    if (extent) {
      mapObject.getView().fit(extent, {
        padding: [100, 100, 100, 100]
      })
    }

    setMap(mapObject)

    return () => mapObject.setTarget(undefined)
  }, [mapRef.current])

  // zoom handler
  useEffect(() => {
    if (!map || !zoom) return
    map.getView().setZoom(zoom)
  }, [zoom])

  // center handler
  useEffect(() => {
    if (!map || !center) return
    map.getView().setCenter(center)
  }, [center])

  // extent handler
  useEffect(() => {
    if (!map) return;
    map.getView().fit(extent, {
      padding: [100, 100, 100, 100]
    })
  }, [extent])

  // map loaded
  useEffect(() => {
    if (!map) return;

    map.on('rendercomplete', (evt) => {
      setMapLoadMessage('Map loaded')
      setMapLoaded(true)
    })

  }, [map])

  // edit mode selection
  useEffect(() => {
    if (!map) return;

    const singleClick = (e: any) => {
      if (!map) return;

      map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {

        // const name = layer.get('name') || null
        const geometry = feature.getGeometry()
        const type = geometry?.getType()

        // zoom extent
        if (geometry && editMode === 'None') {
          if (type === 'Point' || type === 'MultiPoint') {
            map.getView().fit(geometry.getExtent(), {
              padding: [100, 100, 100, 100],
              minResolution: 3,
              duration: 200
            })
          } else {
            map.getView().fit(geometry.getExtent(), {
              padding: [100, 100, 100, 100],
              duration: 200
            })
          }
        }
      })
    }

    if (editMode === 'None') {
      map.on('singleclick', singleClick)
    } else {
      map.un('singleclick', singleClick)
    }

    return () => {
      map.un('singleclick', singleClick)
    }
  }, [map, editMode, editFeatures])

  return (
    <MapContext.Provider
      value={{
        // map
        map,
        mapLoadMessage,
        setMapLoadMessage,
        // sources
        foxtownSource, setFoxtownSource,
        // tiles
        lidarEnabled, setLidarEnabled,
        lidarOpacity, setLidarOpacity,
        satelliteEnabled, setSatelliteEnabled,
        satelliteOpacity, setSatelliteOpacity,
        usfsEnabled, setUsfsEnabled,
        usfsOpacity, setUsfsOpacity,
        // edit mode
        editMode, setEditMode,
        // location
        locationEnabled, followLocation, toggleFollowLocation,
        heading, setHeading,
        // controls
        editFeatures, setEditFeatures,
        // details
        detailsModal, setDetailsModal,
        // admin
        foxtownRep, setFoxtownRep,
        foxtownPass, setFoxtownPass,
        foxtownHash, setFoxtownHash
      }
    }>
      <Joyride />

      <main className="relative overflow-scroll">
        <div ref={mapRef} className="h-screen w-screen">
          {children}
        </div>
      </main>

      <Navbar />
      <Menu />
      <Settings />
      <Sync />
      <Data />

      {/* <Loader loaded={mapLoaded} message={mapLoadMessage} /> */}

    </MapContext.Provider>
  )
}