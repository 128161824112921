// import libs
import React from "react";
import { useAppContext } from "../app";
import { useMapContext } from "../map";

// import components
import { Drawer, Header, Box, Section } from "../shared-ui";

export const Settings = () => {
  const { openSettings, setOpenSettings } = useAppContext();

  const {
    lidarEnabled,
    lidarOpacity,
    setLidarEnabled,
    setLidarOpacity,
    satelliteEnabled,
    satelliteOpacity,
    setSatelliteEnabled,
    setSatelliteOpacity,
    usfsEnabled,
    usfsOpacity,
    setUsfsEnabled,
    setUsfsOpacity,
    // editFeatures,
    // setEditFeatures,
  } = useMapContext();

  return (
    <Drawer isOpen={openSettings} setIsOpen={setOpenSettings}>
      <Header title="Settings" setIsOpen={setOpenSettings} />
      <Box title="Maps">
        <Section>
          {/* Lidar */}
          <div className="form-control mb-5">
            <p></p>
            <div className="flex justify-between">
              <label>Lidar Opacity: {lidarOpacity}%</label>
              <span>Enabled?</span>
            </div>
            <div className="flex items-center gap-10">
              <input
                type="range"
                min="0"
                max="100"
                value={lidarOpacity}
                className="range range-md mt-3"
                onChange={(e) => {
                  setLidarOpacity(parseInt(e.target.value));
                }}
              />
              <input
                type="checkbox"
                checked={lidarEnabled}
                className="checkbox"
                onChange={(e) => {
                  setLidarEnabled(e.target.checked)
                }}
              />
            </div>
          </div>

          {/* Satellite */}
          <div className="form-control mb-5">
            <p></p>
            <div className="flex justify-between">
              <label>Satellite Opacity: {satelliteOpacity}%</label>
              <span>Enabled?</span>
            </div>
            <div className="flex items-center gap-10">
              <input
                type="range"
                min="0"
                max="100"
                value={satelliteOpacity}
                className="range range-md mt-3"
                onChange={(e) => {
                  setSatelliteOpacity(parseInt(e.target.value));
                }}
              />
              <input
                type="checkbox"
                checked={satelliteEnabled}
                className="checkbox"
                onChange={(e) => setSatelliteEnabled(e.target.checked)}
              />
            </div>
          </div>

          {/* Satellite */}
          <div className="form-control mb-5">
            <div className="flex justify-between">
              <label>Forest Service Opacity: {usfsOpacity}%</label>
              <span>Enabled?</span>
            </div>
            <div className="flex items-center gap-10">
              <input
                type="range"
                min="0"
                max="100"
                value={usfsOpacity}
                className="range range-md mt-3"
                onChange={(e) => {
                  setUsfsOpacity(parseInt(e.target.value));
                }}
              />
              <input
                type="checkbox"
                checked={usfsEnabled}
                className="checkbox"
                onChange={(e) => setUsfsEnabled(e.target.checked)}
              />
            </div>
          </div>
        </Section>
      </Box>

      {/* <Box title="Controls">
        <Section>
          <div className="form-control mb-5">
            <div className="flex justify-between mb-2">
              <label>Edit Features</label>
              <span>Enabled?</span>
            </div>
            <label className="flex justify-between">
              <span className="text-2xs">Edit features on the map</span>
              <input
                type="checkbox"
                checked={editFeatures}
                className="checkbox"
                onChange={(e) => setEditFeatures(e.target.checked)}
              />
            </label>
          </div>
        </Section>
      </Box> */}
    </Drawer>
  );
};

export default Settings;
