// import libs
import { TileArcGISRest, OSM, XYZ } from 'ol/source'
import PouchDB from "pouchdb"
import { isBrowser, isOnline } from "../../utils"

/**
 * tileDB
 */
let tileDb: any;
if (isBrowser) {
  tileDb = new PouchDB('offline-tiles')
}

/**
 * Basemap
 */
export const baseMap = (offlineTiles: any) => {
  return new OSM({
    cacheSize: 10000,
    // tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, offlineTiles, 'osm')
    tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, 'osm')
  })
}

/**
 * Lidar Map
 */
const lidarUrl =
  `https://kyraster.ky.gov/arcgis/rest/services/ElevationServices/` +
  `Ky_DEM_KYAPED_5FT_ShadedRelief_WGS84WM/ImageServer/exportImage?f=image&bandIds=1,1,1`
export const lidarMap = (offlineTiles: any) => {
  return new TileArcGISRest({
    url: lidarUrl,
    cacheSize: 10000,
    // tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, offlineTiles, 'lidar'),
    tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, 'lidar'),
    crossOrigin: "anonymous"
  })
}

/**
 * Satellite Map
 */
const satelliteUrl =
  `https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer`
export const satelliteMap = (offlineTiles: any) => {
  return new TileArcGISRest({
    url: satelliteUrl,
    cacheSize: 10000,
    // tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, offlineTiles, 'satellite')
    tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, 'satellite')
  })
}

/**
 * USFS Map
 */
const usfsUrl =
  `https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_FSTopo_01/MapServer/` +
  `tile/{z}/{y}/{x}?blankTile=false`
export const usfsMap = (offlineTiles: any) => {
  return new XYZ({
    url: usfsUrl,
    cacheSize: 10000,
    // tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, offlineTiles, 'usfs')
    tileLoadFunction: async (tile, src) => await loadOfflineTile(tile, src, 'usfs')
  })
}

/**
 * Load Offline Tile
 * @param tile
 * @param src
 * @param map
 */
// const loadOfflineTile = async (tile: any, src: string, offlineTiles: any, map: string) => {
//   // processing related
//   const coords = tile.tileCoord
//   const _id = `${map}_${coords.join('_')}`

//   // cache related
//   const maxAge = 30 * 24 * 3600 * 1000 // 30 days
//   const currentTime = Date.now()

//   // offline tile by _id
//   const offlineTile = offlineTiles.find((t: any) => t.id === _id)

//   /**
//    * Offline Tiles
//    * Recache as necessary
//    */
//   if (offlineTile) {
//     const blob = offlineTile.doc._attachments.tile.data
//     const tileUrl = URL.createObjectURL(blob)
//     tile.getImage().src = tileUrl

//     // recache if necessary
//     if ((currentTime - offlineTile.doc.timestamp) > maxAge && isOnline) {
//       saveOfflineTile(tile, src, _id)
//     }
//   }
//   /**
//    * Offline Tile Unavailable
//    * Add tile to cache
//    */
//   else {
//     tile.getImage().src = src
//     saveOfflineTile(tile, src, _id)
//   }
// }

const loadOfflineTile = async (tile: any, src: string, map: string) => {
  // processing related
  const coords = tile.tileCoord
  const _id = `${map}_${coords.join('_')}`

  // cache related
  const maxAge = 6 * 30 * 24 * 3600 * 1000 // 180 days
  const currentTime = Date.now()

  await tileDb.get(_id, {
    attachments: true
  })
    /**
     * Load existing tile
     */
    .then(async (doc: any) => {

      // recache tiles as needed
      let recache = new Promise((resolve) => {
        if ((currentTime - doc.timestamp) > maxAge && isOnline) {
          return resolve(saveOfflineTile(tile, src, _id))
        } else {
          return resolve(false)
        }
      })

      return recache.then((blob) => {
        if (blob) {
          // @ts-expect-error
          const tileUrl = URL.createObjectURL(blob)
          tile.getImage().src = tileUrl
        } else {
          tileDb.getAttachment(_id, "tile")
            .then((blob: Blob) => {
              const tileUrl = URL.createObjectURL(blob)
              tile.getImage().src = tileUrl
            }).catch(() => {
              const blob = saveOfflineTile(tile, src, _id)
              // @ts-expect-error
              const tileUrl = URL.createObjectURL(blob)
              tile.getImage().src = tileUrl
            })
        }
      })

    })
    /**
     * Save new tile
     */
    .catch(async () => {
      return await new Promise((resolve) => {
        return resolve(saveOfflineTile(tile, src, _id))
      }).then((blob) => {
        // @ts-expect-error
        const tileUrl = URL.createObjectURL(blob)
        tile.getImage().src = tileUrl
      })
    })
}

/**
 * Save offline tile
 * @param tile openlayers tile
 * @param src image src
 * @param _id pouchdb image id
 */
const saveOfflineTile = async (tile: any, src: string, _id: string) => {
  // processing vars
  const image = tile.getImage()

  // create img element
  let img = document.createElement("img")

  // image initial loading params
  img.crossOrigin = "Anonymous"
  img.src = src

  return await new Promise((resolve) => {
    return img.onload = () => {
      // create canvas
      let canvas = document.createElement("canvas")
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      // canvas context
      let ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)

      // save blob to database
      let dataBlob: any;
      canvas.toBlob((blob) => {
        putAttachment(_id, blob)

        resolve(blob)
      })

      // remove img and document from the dom
      img.remove()
      canvas.remove()
    }
  }).then((blob) => {
    return (blob)
  }).catch((error) => {
    console.log(error.message)
    return null
  })
}


/**
 * Put tile blob into pouchdb
 * @param _id string
 * @param blob blob
 * @returns void
 */
const putAttachment = async (_id: string, blob: Blob | null) => {
  if (!blob) return;

  return tileDb.put({
    _id: _id,
    _attachments: {
      "tile": {
        content_type: blob.type,
        data: blob
      }
    },
    timestamp: Date.now()
  }).catch(() => {
    tileDb.get(_id)
      .then((doc: any) => {
        return tileDb.put({
          _id: _id,
          _rev: doc._rev,
          _attachments: {
            "tile": {
              content_type: blob.type,
              data: blob
            }
          },
          timestamp: Date.now()
        })
      })
  })
}