// import libs
import React from "react";
import type { HeadProps } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

export const Seo = ({
  location,
  params,
  data,
  pageContext,
}: HeadProps<DataProps>) => {
  const { socialGraphic, site } = useStaticQuery(graphql`
    query {
      socialGraphic: file(relativePath: { eq: "foxtown-social.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
      site {
        siteMetadata {
          title
          siteUrl
          siteDescription
        }
      }
    }
  `);

  // share image
  const shareImage = `${site.siteMetadata.siteUrl}${getSrc(socialGraphic)}`;

  // meta information
  const meta = [
    // {
    //   property: "viewport",
    //   content:
    //     "width=device-width, initial-scale=1.0, user-scalable=no, viewport-fit=cover",
    // },
    // {
    //   property: "apple-mobile-web-app-title",
    //   content: site.siteMetadata.title,
    // },
    // {
    //   property: "apple-mobile-web-app-capable",
    //   content: "yes",
    // },
    // {
    //   property: "apple-mobile-web-app-status-bar-style",
    //   content: "black-translucent",
    // },
    {
      name: "description",
      content: site.siteMetadata.siteDescription,
    },
    {
      property: "fb:app_id",
      content: "517569353543752",
    },
    {
      property: "og:url",
      content: site.siteMetadata.siteUrl,
    },
    {
      property: "og:title",
      content: site.siteMetadata.title,
    },
    {
      property: "og:description",
      content: site.siteMetadata.siteDescription,
    },
    {
      property: "og:image",
      content: shareImage,
    },
    {
      property: "og:image:alt",
      content: site.siteMetadata.title,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "twitter:image",
      content: shareImage,
    },
    {
      property: "twitter:creator",
      content: site.siteMetadata.authorName,
    },
    {
      property: "twitter:title",
      content: site.siteMetadata.title,
    },
    {
      property: "twitter:description",
      content: site.siteMetadata.siteDescription,
    },
  ];

  return (
    <>
      <title>{site.siteMetadata.title}</title>
      <link rel="canonical" href={site.siteMetadata.siteUrl} />
      {meta.map((item, idx) => {
        return <meta key={idx} {...item} />;
      })}
    </>
  );
};
