// import libs
import React, { Dispatch, SetStateAction } from "react";
import { useSwipeable } from "react-swipeable";

export interface IDrawerProps {
  children: React.ReactNode,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const Drawer = ({ children, isOpen, setIsOpen }: IDrawerProps) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setIsOpen(false);
    },
  });

  return (
    <div
      {...handlers}
      className={
        "fixed overflow-hidden z-50 inset-0 transform " +
        (isOpen
          ? "transition-all duration-200 ease-out-in translate-x-0"
          : "transition-all duration-300 delay-300 ease-in-out translate-x-[-100%]")
      }
    >
      <div className="flex flex-row h-screen">
        <div
          className={
            "w-screen max-w-lg left-0 bg-white/30 backdrop-blur-md h-full overflow-y-scroll transition-all transform flex flex-col justify-between " +
            (isOpen ? "translate-x-0" : "translate-x-[-100%]")
          }
        >
          <div className="pb-10">{children}</div>
        </div>
        <div
          className={
            "grow h-full min-w-max cursor-pointer bg-black/60 " +
            (isOpen
              ? "transition-opacity duration-200 opacity-100"
              : "transition-opacity duration-75 opacity-0")
          }
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Drawer;
