// import libs
import React, { useEffect, useState } from "react";
import { useAppContext } from "../app/app-context";
import { useMapContext } from "../map";

// import components
import { FoxtownIcon } from "../shared-ui";
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { TiLocationArrowOutline } from "react-icons/ti";
import { HiOutlineLocationMarker } from "react-icons/hi"
import { TbPolygon } from "react-icons/tb"
import { GiPathDistance } from "react-icons/gi"
import { MdOutlineModeEdit, MdOutlineEditOff } from "react-icons/md"

export const Navbar = () => {
  const {
    openMenu,
    setOpenMenu,
    openSettings,
    setOpenSettings,
    openData,
    setOpenData,
    openSync,
    setOpenSync,
  } = useAppContext();

  const {
    locationEnabled,
    followLocation,
    heading,
    toggleFollowLocation,
    editMode,
    setEditMode,
    editFeatures,
    setEditFeatures
  } = useMapContext();

  const isActive = (active: boolean) => {
    return active ? "bg-emerald-600 text-white" : "bg-white/40 text-gray-800";
  };

  // location stages
  const locationActive = () => {
    if (locationEnabled && !followLocation) {
      return "bg-emerald-600 text-white"
    }
    if (locationEnabled && followLocation) {
      return "bg-blue-600 text-white"
    }
    return "bg-white/40 text-gray-800"
  }

  const buttonClass = `w-10 h-10 rounded-md flex justify-center items-center mt-2 backdrop-blur-sm`;
  const editButtonClass = `w-10 h-10 flex justify-center items-center backdrop-blur-sm`;

  const [pinHeading, setPinHeading] = useState<number>(heading);

  useEffect(() => {
    setPinHeading(heading);
  }, [heading]);

  return (
    <nav className="absolute right-3 top-0 flex flex-col justify-between z-40 h-screen">
      <div className="standalone:mt-10">
        {/* top buttons */}
        <button
          className={`${isActive(openMenu)} ${buttonClass} foxtown-menu`}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <FoxtownIcon size={23} className={openMenu ? "filter-white" : ""} />
        </button>
        <button
          className={`${isActive(openData)} ${buttonClass} foxtown-search`}
          onClick={() => setOpenData(!openData)}
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="mb-8 md:mb-3">
        <div>
          {/* location button */}
          <button
            className={`${locationActive()} ${buttonClass} foxtown-location`}
            onClick={() => toggleFollowLocation()}
          >
            <div className={`${locationEnabled ? "rotate-[-45deg]" : ""}`}>
              {locationEnabled && (
                <TiLocationArrowOutline
                  className={`h-5 w-5 transform transition-all delay-100 duration-700 ease-in-out`}
                  style={{ rotate: `${pinHeading}rad` }}
                />
              )}
              {!locationEnabled && <TiLocationArrowOutline
                className={`h-5 w-5`}
              />}
            </div>
          </button>
        </div>
        <div className="md:mb-3 foxtown-map-tools">
          {/* edit buttons */}
          {/* <button
          className={`${isActive(true)} ${buttonClass}`}
          onClick={() => navigate("/")}
        >
          <MapIcon className="h-5 w-5" />
        </button> */}
          <button
            className={`${isActive(editMode === 'Point')} ${editButtonClass} mt-2 rounded-tl-md rounded-tr-md foxtown-add-point`}
            onClick={() => {
              setEditMode(editMode === 'Point' ? 'None' : 'Point')
              setEditFeatures(false)
            }}
          >
            <HiOutlineLocationMarker className="h-5 w-5" />
          </button>
          <button
            className={`${isActive(editMode === 'Polygon')} ${editButtonClass} border-black/10 border-t foxtown-add-polygon`}
            onClick={() => {
              setEditMode(editMode === 'Polygon' ? 'None' : 'Polygon')
              setEditFeatures(false)
            }}
          >
            <TbPolygon className="h-5 w-5" />
          </button>
          <button
            className={`${isActive(editMode === 'LineString')} ${editButtonClass} border-black/10 border-t foxtown-add-line`}
            onClick={() => {
              setEditMode(editMode === 'LineString' ? 'None' : 'LineString')
              setEditFeatures(false)
            }}
          >
            <GiPathDistance className="h-5 w-5" />
          </button>
          <button
            className={`${isActive(editFeatures)} ${editButtonClass} border-black/10 border-t rounded-bl-md rounded-br-md foxtown-edit-features`}
            onClick={() => {
              setEditFeatures(!editFeatures)
              setEditMode('None')
            }}
          >
            {editFeatures && (
              <MdOutlineModeEdit className="h-5 w-5" />
            )}
            {!editFeatures && (
              <MdOutlineEditOff className="h-5 w-5" />
            )}
          </button>
        </div>
        <div>
          {/* More Settings */}
          <button
            className={`${isActive(openSettings)}  ${buttonClass} foxtown-settings`}
            onClick={() => setOpenSettings(!openSettings)}
          >
            <AdjustmentsHorizontalIcon className="h-5 w-5" />
          </button>
          <button
            className={`${isActive(openSync)} ${buttonClass} foxtown-sync`}
            onClick={() => setOpenSync(!openSync)}
          >
            <ArrowPathIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
